export const CERTIFICATE_QUOTE = "CERTIFICATE_QUOTE";
export const CERTIFICATE_QUOTE_SUCCESS = "CERTIFICATE_QUOTE_SUCCESS";
export const CERTIFICATE_QUOTE_ERROR = "CERTIFICATE_QUOTE_ERROR";
export const CERTIFICATE_QUOTE_CLEAR_DATA = "CERTIFICATE_QUOTE_CLEAR_DATA";

export const CERTIFICATE_TEMPLATE = "CERTIFICATE_TEMPLATE";
export const CERTIFICATE_TEMPLATE_SUCCESS = "CERTIFICATE_TEMPLATE_SUCCESS";
export const CERTIFICATE_TEMPLATE_ERROR = "CERTIFICATE_TEMPLATE_ERROR";
export const CERTIFICATE_TEMPLATE_CLEAR_DATA =
  "CERTIFICATE_TEMPLATE_CLEAR_DATA";

export const CERTIFICATE_TO_PROJECT = "CERTIFICATE_TO_PROJECT";
export const CERTIFICATE_TO_PROJECT_SUCCESS = "CERTIFICATE_TO_PROJECT_SUCCESS";
export const CERTIFICATE_TO_PROJECT_ERROR = "CERTIFICATE_TO_PROJECT_ERROR";
export const CERTIFICATE_TO_PROJECT_CLEAR_DATA = "CERTIFICATE_TO_PROJECT_DATA";

export const CREATE_CERTIFICATE = "CREATE_CERTIFICATE";
export const CREATE_CERTIFICATE_SUCCESS = "CREATE_CERTIFICATE_SUCCESS";
export const CREATE_CERTIFICATE_ERROR = "CREATE_CERTIFICATE_ERROR";
export const CREATE_CERTIFICATE_CLEAR_DATA = "CREATE_CERTIFICATE_CLEAR_DATA";

export const CERTIFICATE_TEMPLATE_ONE = "CERTIFICATE_TEMPLATE_ONE";
export const CERTIFICATE_TEMPLATE_ONE_SUCCESS =
  "CERTIFICATE_TEMPLATE_ONE_SUCCESS";
export const CERTIFICATE_TEMPLATE_ONE_ERROR = "CERTIFICATE_TEMPLATE_ONE_ERROR";
export const CERTIFICATE_TEMPLATE_ONE_CLEAR_DATA =
  "CERTIFICATE_TEMPLATE_ONE_CLEAR_DATA";

export const PUT_CERT_TEMPLATE = "PUT_CERT_TEMPLATE";
export const PUT_CERT_TEMPLATE_SUCCESS = "PUT_CERT_TEMPLATE_SUCCESS";
export const PUT_CERT_TEMPLATE_ERROR = "PUT_CERT_TEMPLATE_ERROR";
export const PUT_CERT_TEMPLATE_CLEAR_DATA = "PUT_CERT_TEMPLATE_CLEAR_DATA";

export const SCHEMAS_TEMPLATE = "SCHEMAS_TEMPLATE";
export const SCHEMAS_TEMPLATE_SUCCESS = "SCHEMAS_TEMPLATE_SUCCESS";
export const SCHEMAS_TEMPLATE_ERROR = "SCHEMAS_TEMPLATE_ERROR";
export const SCHEMAS_TEMPLATE_CLEAR_DATA = "SCHEMAS_TEMPLATE_CLEAR_DATA";

export const PATCH_CERT_TEMPLATE = "PATCH_CERT_TEMPLATE";
export const PATCH_CERT_TEMPLATE_SUCCESS = "PATCH_CERT_TEMPLATE_SUCCESS";
export const PATCH_CERT_TEMPLATE_ERROR = "PATCH_CERT_TEMPLATE_ERROR";
export const PATCH_CERT_TEMPLATE_CLEAR_DATA = "PATCH_CERT_TEMPLATE_CLEAR_DATA";