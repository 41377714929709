import { URLs } from "../url";
import { getHeader } from "../headers";
import { fetchRequest, handleUnauthorized } from "../fetchRequest";
import {
  CertificateTemplateResponse,
  CreateCertificateResponse,
} from "../../model/certificates";

const getCertificatesTemplate = async (
  enable: boolean,
  page: number,
  limit: number,
  title?: string,
  projectId?: string,
  certType?: string,
) => {
  let newURL =
    `${process.env.REACT_APP_HOST_API}${URLs.certTemplates}?enable=${enable}&page=${page}&limit=${limit}` +
    (title ? `&title=${title}` : "") +
    (projectId ? `&projectId=${projectId}` : "") +
    (certType ? `&certType=${certType}` : "");

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as CertificateTemplateResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const postCertificateToProject = async (id: string, idProject: string) => {
  const newURL = `${process.env.REACT_APP_HOST_API}${URLs.certTemplates}/${id}/Projects?idProject=${idProject}`;
  const requestOptions = {
    method: "POST",
    headers: getHeader(),
  };
  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await fetchRequest(newURL, requestOptions)) as any;
  } catch (error: any) {
    throw error;
  }
};

const postCreateCertificate = async (body: any) => {
  const newURL = `${process.env.REACT_APP_HOST_API}${URLs.certTemplates}/Metadata`;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(body),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as CreateCertificateResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getOneCertificatesTemplate = async (certificateId: string) => {
  let newURL = `${process.env.REACT_APP_HOST_API}${URLs.certTemplates}/${certificateId}/Metadata`;

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as CertificateTemplateResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const putCertificateTemplate = async (body: any, id: string) => {
  const newURL = `${process.env.REACT_APP_HOST_API}${URLs.certTemplates}/${id}/Metadata`;
  debugger;
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(body),
    headers: getHeader(),
  };
  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as CreateCertificateResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getAllSchemasTemplateApi = async () => {
  let newURL = `${process.env.REACT_APP_HOST_API}${URLs.schemas}/MultipleSchemas`;

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as any;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const deleteCertificateTemplate = async (certificateId: string, userId: string, body: any) => {
  let newURL = `${process.env.REACT_APP_HOST_API}${URLs.certificateDocumentEntity}/${certificateId}/user-mod/${userId}`;
  const requestOptions = {
    method: "PATCH",
    headers: getHeader(),
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as any;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export {
  getCertificatesTemplate,
  postCertificateToProject,
  postCreateCertificate,
  getOneCertificatesTemplate,
  putCertificateTemplate,
  getAllSchemasTemplateApi,
  deleteCertificateTemplate
};
