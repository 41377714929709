import {
  CERTIFICATE_QUOTE,
  CERTIFICATE_QUOTE_CLEAR_DATA,
  CERTIFICATE_QUOTE_ERROR,
  CERTIFICATE_QUOTE_SUCCESS,
  CERTIFICATE_TEMPLATE,
  CERTIFICATE_TEMPLATE_CLEAR_DATA,
  CERTIFICATE_TEMPLATE_ERROR,
  CERTIFICATE_TEMPLATE_ONE,
  CERTIFICATE_TEMPLATE_ONE_CLEAR_DATA,
  CERTIFICATE_TEMPLATE_ONE_ERROR,
  CERTIFICATE_TEMPLATE_ONE_SUCCESS,
  CERTIFICATE_TEMPLATE_SUCCESS,
  CERTIFICATE_TO_PROJECT,
  CERTIFICATE_TO_PROJECT_CLEAR_DATA,
  CERTIFICATE_TO_PROJECT_ERROR,
  CERTIFICATE_TO_PROJECT_SUCCESS,
  CREATE_CERTIFICATE,
  CREATE_CERTIFICATE_CLEAR_DATA,
  CREATE_CERTIFICATE_ERROR,
  CREATE_CERTIFICATE_SUCCESS,
  PUT_CERT_TEMPLATE,
  PUT_CERT_TEMPLATE_CLEAR_DATA,
  PUT_CERT_TEMPLATE_ERROR,
  PUT_CERT_TEMPLATE_SUCCESS,
  SCHEMAS_TEMPLATE,
  SCHEMAS_TEMPLATE_CLEAR_DATA,
  SCHEMAS_TEMPLATE_ERROR,
  SCHEMAS_TEMPLATE_SUCCESS,
  PATCH_CERT_TEMPLATE,
  PATCH_CERT_TEMPLATE_SUCCESS,
  PATCH_CERT_TEMPLATE_ERROR,
  PATCH_CERT_TEMPLATE_CLEAR_DATA,
} from "./actionTypes";

const initialState = {
  errorCertificateQuote: null,
  responseCertificateQuote: null,
  loadingCertificateQuote: false,

  loadingCertificateTemplate: false,
  responseCertificateTemplate: null,
  errorCertificateTemplate: null,

  loadingCertificateToProject: false,
  responseCertificateToProject: null,
  errorCertificateToProject: null,

  loadingCreateCertificate: false,
  responseCreateCertificate: null,
  errorCreateCertificate: null,

  loadingOneCertTemplate: false,
  responseOneCertTemplate: null,
  errorOneCertTemplate: null,

  loadingPutCertificate: false,
  responsePutCertificate: null,
  errorPutCertificate: null,

  loadingSchemasTemplate: false,
  responseSchemasTemplate: null,
  errorSchemasTemplate: null,

  loadingPatchCertTemplate: false,
  responsePatchCertTemplate: null,
  errorPatchCertTemplate: null,
};

const Certificates = (state = initialState, action) => {
  switch (action.type) {
    case CERTIFICATE_QUOTE:
      state = {
        ...state,
        loadingCertificateQuote: true,
        errorCertificateQuote: null,
        responseCertificateQuote: null,
      };
      break;
    case CERTIFICATE_QUOTE_SUCCESS:
      state = {
        ...state,
        errorCertificateQuote: null,
        loadingCertificateQuote: false,
        responseCertificateQuote: action.payload,
      };
      break;
    case CERTIFICATE_QUOTE_ERROR:
      state = {
        ...state,
        errorCertificateQuote: action.payload,
        loadingCertificateQuote: false,
        responseCertificateQuote: null,
      };
      break;
    case CERTIFICATE_QUOTE_CLEAR_DATA:
      state = {
        ...state,
        errorCertificateQuote: null,
        responseCertificateQuote: false,
        loadingCertificateQuote: null,
      };
      break;
    /////
    case CERTIFICATE_TEMPLATE:
      state = {
        ...state,
        loadingCertificateTemplate: true,
        responseCertificateTemplate: null,
        errorCertificateTemplate: null,
      };
      break;
    case CERTIFICATE_TEMPLATE_SUCCESS:
      state = {
        ...state,
        loadingCertificateTemplate: false,
        responseCertificateTemplate: action.payload,
        errorCertificateTemplate: null,
      };
      break;
    case CERTIFICATE_TEMPLATE_ERROR:
      state = {
        ...state,
        loadingCertificateTemplate: false,
        responseCertificateTemplate: null,
        errorCertificateTemplate: action.payload,
      };
      break;
    case CERTIFICATE_TEMPLATE_CLEAR_DATA:
      state = {
        ...state,
        loadingCertificateTemplate: null,
        responseCertificateTemplate: null,
        errorCertificateTemplate: null,
      };
      break;
    ////
    case CERTIFICATE_TO_PROJECT:
      state = {
        ...state,
        loadingCertificateToProject: true,
        responseCertificateToProject: null,
        errorCertificateToProject: null,
      };
      break;
    case CERTIFICATE_TO_PROJECT_SUCCESS:
      state = {
        ...state,
        loadingCertificateToProject: false,
        responseCertificateToProject: action.payload,
        errorCertificateToProject: null,
      };
      break;
    case CERTIFICATE_TO_PROJECT_ERROR:
      state = {
        ...state,
        loadingCertificateToProject: false,
        responseCertificateToProject: null,
        errorCertificateToProject: action.payload,
      };
      break;
    case CERTIFICATE_TO_PROJECT_CLEAR_DATA:
      state = {
        ...state,
        loadingCertificateToProject: null,
        responseCertificateToProject: null,
        errorCertificateToProject: null,
      };
      break;
    ////
    case CREATE_CERTIFICATE:
      state = {
        ...state,
        loadingCreateCertificate: true,
        responseCreateCertificate: null,
        errorCreateCertificate: null,
      };
      break;
    case CREATE_CERTIFICATE_SUCCESS:
      state = {
        ...state,
        loadingCreateCertificate: false,
        responseCreateCertificate: action.payload,
        errorCreateCertificate: null,
      };
      break;
    case CREATE_CERTIFICATE_ERROR:
      state = {
        ...state,
        loadingCreateCertificate: false,
        responseCreateCertificate: null,
        errorCreateCertificate: action.payload,
      };
      break;
    case CREATE_CERTIFICATE_CLEAR_DATA:
      state = {
        ...state,
        loadingCreateCertificate: null,
        responseCreateCertificate: null,
        errorCreateCertificate: null,
      };
      break;
    //////
    case CERTIFICATE_TEMPLATE_ONE:
      state = {
        ...state,
        loadingOneCertTemplate: true,
        responseOneCertTemplate: null,
        errorOneCertTemplate: null,
      };
      break;
    case CERTIFICATE_TEMPLATE_ONE_SUCCESS:
      state = {
        ...state,
        loadingOneCertTemplate: false,
        responseOneCertTemplate: action.payload,
        errorOneCertTemplate: null,
      };
      break;
    case CERTIFICATE_TEMPLATE_ONE_ERROR:
      state = {
        ...state,
        loadingOneCertTemplate: false,
        responseOneCertTemplate: null,
        errorOneCertTemplate: action.payload,
      };
      break;
    case CERTIFICATE_TEMPLATE_ONE_CLEAR_DATA:
      state = {
        ...state,
        loadingOneCertTemplate: null,
        responseOneCertTemplate: null,
        errorOneCertTemplate: null,
      };
      break;
    //////
    case PUT_CERT_TEMPLATE:
      state = {
        ...state,
        loadingPutCertificate: true,
        responsePutCertificate: null,
        errorPutCertificate: null,
      };
      break;
    case PUT_CERT_TEMPLATE_SUCCESS:
      state = {
        ...state,
        loadingPutCertificate: false,
        responsePutCertificate: action.payload,
        errorPutCertificate: null,
      };
      break;
    case PUT_CERT_TEMPLATE_ERROR:
      state = {
        ...state,
        loadingPutCertificate: false,
        responsePutCertificate: null,
        errorPutCertificate: action.payload,
      };
      break;
    case PUT_CERT_TEMPLATE_CLEAR_DATA:
      state = {
        ...state,
        loadingPutCertificate: null,
        responsePutCertificate: null,
        errorPutCertificate: null,
      };
      break;
    //////
    case SCHEMAS_TEMPLATE:
      state = {
        ...state,
        loadingSchemasTemplate: true,
        responseSchemasTemplate: null,
        errorSchemasTemplate: null,
      };
      break;
    case SCHEMAS_TEMPLATE_SUCCESS:
      state = {
        ...state,
        loadingSchemasTemplate: false,
        responseSchemasTemplate: action.payload,
        errorSchemasTemplate: null,
      };
      break;
    case SCHEMAS_TEMPLATE_ERROR:
      state = {
        ...state,
        loadingSchemasTemplate: false,
        responseSchemasTemplate: null,
        errorSchemasTemplate: action.payload,
      };
      break;
    case SCHEMAS_TEMPLATE_CLEAR_DATA:
      state = {
        ...state,
        loadingSchemasTemplate: null,
        responseSchemasTemplate: null,
        errorSchemasTemplate: null,
      };
      break;
    //////
    case PATCH_CERT_TEMPLATE:
      state = {
        ...state,
        loadingPatchCertTemplate: true,
        responsePatchCertTemplate: null,
        errorPatchCertTemplate: null,
      };
      break;
    case PATCH_CERT_TEMPLATE_SUCCESS:
      state = {
        ...state,
        loadingPatchCertTemplate: false,
        responsePatchCertTemplate: action.payload,
        errorPatchCertTemplate: null,
      };
      break;
    case PATCH_CERT_TEMPLATE_ERROR:
      state = {
        ...state,
        loadingPatchCertTemplate: false,
        responsePatchCertTemplate: null,
        errorPatchCertTemplate: action.payload,
      };
      break;
    case PATCH_CERT_TEMPLATE_CLEAR_DATA:
      state = {
        ...state,
        loadingPatchCertTemplate: null,
        responsePatchCertTemplate: null,
        errorPatchCertTemplate: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Certificates;
