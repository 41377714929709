import React, { useEffect } from "react";
import { useStyles } from "../assets/style";
import "../assets/style.css";

import {
  ButtonAFY,
  GridAFY,
  icons,
  InformationAFY,
  InputAFY,
  InsurancePolicyReportsAFY,
  ModalComponentAFY,
  SelectAFY,
  sizes,
  sizesIcon,
  sizesSelect,
  sizesTextField,
  variants,
  variantsTextField,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { Title } from "../../../components/layout/style";
import { getFilters } from "../resources/insurancePoliciesDTO";

const View = (props: any) => {
  const classes = useStyles();
  const [reasonCancellation, setReasonCancellation] = React.useState<any>("");
 const [limit, setLimit] = React.useState<number>(props.limit);

  const handleChange = (value: any) => {
    setReasonCancellation(value);
  };

  useEffect(() => {
    if (props.limit !== limit) {
      setLimit(props.limit);
    }
  }
  , [props.limit]);


  useEffect(() => {
    if (props.reasonCancellation.length > 0)
      setReasonCancellation(props.reasonCancellation[0].value);
  }, [props.reasonCancellation]);

  return (
    <React.Fragment>
      <Title>Pólizas</Title>
      <InformationAFY
        backgroundColor="#F5F5F5"
        colorText="#666666"
        description="Si desea aplicar un filtro al resultado de ventas completo, debe seleccionar los diferentes filtros que se muestra en la parte superior."
        icon={icons.Help}
        iconColor="#3A56A1"
        iconSize={sizesIcon.standard}
      />
      <ModalComponentAFY
        openModal={props.openModalConfirm}
        backgroundColor="#ffffff"
        onClose={props.onCloseConfirm}
        border={0}
        minWidthModal={"500px"}
        padding={40}
      >
        <div>
          <h2 className={classes.title}>Confirmación </h2>
          <div className={classes.components}>
            <div className={classes.component}>
              <div className={classes.label}>{"Seleccione la razón"}</div>
              <SelectAFY
                value={""}
                variant={variantsTextField.filled}
                disableUnderline={true}
                fontSize={14}
                size={sizesSelect.small}
                options={props.reasonCancellation}
                onChange={(e: any) => {
                  e.stopPropagation();
                  handleChange(e.target.value);
                }}
              />
            </div>
            <div className={classes.component}>
              <div className={classes.label}>{"Ingrese la contraseña"}</div>
              <InputAFY
                defaultValue={""}
                fullWidth
                type="password"
                size={sizesTextField.small}
                variant={variantsTextField.outlined}
              />
            </div>
          </div>
          <div className={classes.buttonsContainerConfirm}>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"Cancelar"}
                backgroundColor="red"
                onClick={props.onCloseConfirm}
              />
            </div>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"Guardar"}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onSubmit(reasonCancellation);
                }}
              />
            </div>
          </div>
        </div>
      </ModalComponentAFY>

      <ModalComponentAFY
        openModal={props.openModal}
        backgroundColor="#ffffff"
        onClose={props.onClose}
        border={0}
        minWidthModal={"500px"}
        padding={30}
      >
        <div style={{ marginTop: "10px" }}>
          <h2 className={classes.title}>Cancelar {props.modalTitle}</h2>
          <p className={classes.description}>
            {`¿Está seguro que desea cancelar la ${props.modalTitle}?`}
          </p>

          <div className={classes.components}>
            <div>
              <div className={classes.modalLabel}>{props.modalTitle}:</div>{" "}
              {props.row.certificate
                ? props.row.certificate
                : props.row.quotation}
            </div>

            <div>
              <div className={classes.modalLabel}>Nombre:</div>{" "}
              {props.row.firstNameA} {props.row.lastNameA}
            </div>
            <div>
              <div className={classes.modalLabel}>Estatus:</div>{" "}
              {props.row.status}
            </div>
          </div>

          <div className={classes.buttonsContainer}>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"No"}
                backgroundColor="red"
                onClick={props.onClose}
              />
            </div>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"Si, cancelar"}
                onClick={(e) => {
                  e.stopPropagation();
                  if (props.modalTitle === "póliza") {
                    props.handleOpenModalConfirm();
                  } else {
                    props.onSubmit("Cancelado");
                  }
                }}
              />
            </div>
          </div>
        </div>
      </ModalComponentAFY>

      <ModalComponentAFY
        openModal={props.OpenModalHistorial}
        backgroundColor="#ffffff"
        onClose={props.onClose}
        border={0}
        minWidthModal={"500px"}
        padding={30}
      >
        <InformationAFY
          backgroundColor="#F5F5F5"
          colorText="#666666"
          description="Historial de movimientos de la cotizacion/poliza"
          icon={icons.Info}
          iconColor="#3A56A1"
          iconSize={sizesIcon.standard}
        />
        <GridAFY
          width={"1000px"}
          height={"500px"}
          pageSize={20}
          columns={props.getColumnsHistory}
          rows={props.rowHistory}
          multipleSelection={false}
        />
      </ModalComponentAFY>

      <div style={{ marginTop: "15px", width: "calc(86vw - 60px)" }}>
        <InsurancePolicyReportsAFY
          labelPagination="Filas por página"
          rowsPerPageOptions={[10,25,50,100]}
          pagePagination={1}
          rowsPerPages={limit}
          columnHeaderHeight={52}
          rowHeightGrid={52}
          totalRecords={1000}
          heightGrid="700px"

          rows={props.rows}
          columns={props.columns}
          filters={getFilters(
            props.insuranceOptions,
            props.statusOptions,
            props.branchOptions,
          )}
          onChangeFilter={props.filterStatus}
          onChangeFullTextSearch={(term) => props.searchFilter(term)}
          hidePaginationGrid={true}
          showPagination={true}
          onChangePage={props.onChangePage}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          onClickExportButton={props.onClickDownload}
        />
      </div>
    </React.Fragment>
  );
};

export default View;
