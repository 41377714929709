import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCertTemplate } from "../../store/certificates/actions";

export const useApiDeleteCertificate = (
    trigger: boolean,
    certId: string,
    userId: any,
) => {
    const dispatch = useDispatch();
    let body = {
        force: false
    }
    const { loadingPatchCertTemplate, responsePatchCertTemplate, errorPatchCertTemplate } =
        useSelector((state: any) => ({
            loadingPatchCertTemplate: state.Certificates.loadingPatchCertTemplate,
            responsePatchCertTemplate: state.Certificates.responsePatchCertTemplate,
            errorPatchCertTemplate: state.Certificates.errorPatchCertTemplate,
        }));
    useEffect(() => {
        if (trigger) {
            dispatch(deleteCertTemplate(certId, userId, body));
        }
    }, [trigger]);

    return {
        loadingPatchCertTemplate,
        responsePatchCertTemplate,
        errorPatchCertTemplate,
    };
};
