const asg = "/asg/Api/V1/";
const asgAuth = "/auth/Api/V1/";
const crecer = "/crecer/Api/V1/";
const apap = "/apap-vida-termino/API/V1/";
const asgDelete = "/asg/Api/V1/delete/";

export const URLs = {
  login: asgAuth + "Users/LogIn",
  byPassLogin: asgAuth + "Users/Decode",
  collection: asg + "Collections/Generate",
  collections: asg + "Collections",
  collectionsUpload: asg + "Collections/Inputs",
  user: asgAuth + "Users",
  spaces: asg + "Spaces",
  contacts: asg + "Contacts",
  insuranceType: asg + "ProjectType",
  insurance: asg + "Project",
  identificationType: asg + "IdentificationType",
  coverType: asg + "CoverType",
  coverTypePlan: asg + "CoverTypePlan",
  premiumEvalOption: asg + "PremiumEvalOption",
  countries: asg + "Countries",
  region: asg + "Region",
  project: asg + "Project",
  sponsor: asg + "Sponsor",
  client: asg + "Client",
  person: asg + "Person",
  plan: asg + "Catalog",
  catalog: asg + "Catalog",
  deleteEntity: asg + "delete",
  plans: asg + "Plan",
  campaign: asg + "Campaign",
  campaignProject: asg + "CampaignProject",
  quote: asg + "Quote",
  insured: asg + "Insured",
  policy: asg + "Policy",
  cancelPolicy: asg + "CancellationPolicy",
  avatar: asg + "Avatars",
  planBenefit: asg + "Benefit",
  beneficiary: asg + "Beneficiary",
  pageActionFlags: asg + "PageActionFlags",
  accountType: asg + "AccountTypes",
  branches: asg + "Branches",
  paymentMode: asg + "PaymentModes",
  projectType: asg + "ProjectType",
  cancelQuote: asg + "CancelQuote",
  currencies: asg + "Currencies",
  rol: asgAuth + "Roles",
  permission: asgAuth + "Permissions",
  certificate: asg + "Certificates",
  certTemplates: asg + "CertTemplates",
  schemas: asg + "Schemas",
  certificateDocumentEntity: asgDelete + "CertificateDocumentEntity",
  //APAP_AUTO
  vehicle: `${crecer}Vehicles`,
  carColor: `${crecer}Vehicles/Colors`,

  //APAP_VIDA_TERMINO
  calculations: `${apap}Calculations`,
};

export const Methods = {
  post: "post",
  get: "get",
  put: "put",
  delete: "delete",
  patch: "patch",
} as const;
