import { call, put, takeEvery } from "redux-saga/effects";
import {
  allSchemasTemplateError,
  allSchemasTemplateSuccess,
  certificateQuoteError,
  certificateQuoteSuccess,
  certificateTemplateError,
  certificateTemplateOneError,
  certificateTemplateOneSuccess,
  certificateTemplateSuccess,
  certificateToProjectError,
  certificateToProjectSuccess,
  createCertificateError,
  createCertificateSuccess,
  putCertTemplateError,
  putCertTemplateSuccess,
  deleteCertTemplateSuccess,
  deleteCertTemplateError,
} from "./actions";
import { getCertificateQuote } from "../../resources/helperAPAP/certificatesQuote";
import {
  CERTIFICATE_QUOTE,
  CERTIFICATE_TEMPLATE,
  CERTIFICATE_TEMPLATE_ONE,
  CERTIFICATE_TO_PROJECT,
  CREATE_CERTIFICATE,
  PUT_CERT_TEMPLATE,
  SCHEMAS_TEMPLATE,
  PATCH_CERT_TEMPLATE
} from "./actionTypes";
import {
  getAllSchemasTemplateApi,
  getCertificatesTemplate,
  getOneCertificatesTemplate,
  postCertificateToProject,
  postCreateCertificate,
  putCertificateTemplate,
  deleteCertificateTemplate,
} from "../../resources/helper/certificates";

function* certificateQuote({ payload: { certificateQuote } }) {
  try {
    const response = yield call(
      getCertificateQuote,
      certificateQuote.title,
      certificateQuote.quoteId,
      certificateQuote.enable,
      certificateQuote.page,
      certificateQuote.limit,
    );
    yield put(certificateQuoteSuccess(response));
  } catch (error) {
    yield put(certificateQuoteError(error));
  }
}

////////

function* certificateTemplate({ payload: { certificateTemplate } }) {
  try {
    const response = yield call(
      getCertificatesTemplate,
      certificateTemplate.enable,
      certificateTemplate.page,
      certificateTemplate.limit,
      certificateTemplate.title,
      certificateTemplate.projectId,
      certificateTemplate.certType,
    );
    yield put(certificateTemplateSuccess(response));
  } catch (error) {
    yield put(certificateTemplateError(error));
  }
}

///////

function* certificateToProject({ payload: { certificateToProject } }) {
  try {
    const response = yield call(
      postCertificateToProject,
      certificateToProject.id,
      certificateToProject.idProject,
    );
    yield put(certificateToProjectSuccess(response));
  } catch (error) {
    yield put(certificateToProjectError(error));
  }
}

///////

function* createCertificate({ payload: { certificateBody } }) {
  try {
    const response = yield call(postCreateCertificate, certificateBody);
    yield put(createCertificateSuccess(response));
  } catch (error) {
    yield put(createCertificateError(error));
  }
}

//////

function* getACertificateTemplate({ payload: { getCertificateOne } }) {
  try {
    const response = yield call(
      getOneCertificatesTemplate,
      getCertificateOne.id,
    );
    yield put(certificateTemplateOneSuccess(response));
  } catch (error) {
    yield put(certificateTemplateOneError(error));
  }
}

///////

function* putCertTemplate({ payload: { certificateBody, id } }) {
  try {
    const response = yield call(putCertificateTemplate, certificateBody, id);
    yield put(putCertTemplateSuccess(response));
  } catch (error) {
    yield put(putCertTemplateError(error));
  }
}

//////

function* getAllSchemasTemplate({ payload: { schemas } }) {
  try {
    const response = yield call(getAllSchemasTemplateApi);
    yield put(allSchemasTemplateSuccess(response));
  } catch (error) {
    yield put(allSchemasTemplateError(error));
  }
}

//////

function* deleteCertTemplate({ payload: { certId, userId, body } }) {
  try {
    const response = yield call(deleteCertificateTemplate, certId, userId, body);
    yield put(deleteCertTemplateSuccess(response));
  } catch (error) {
    yield put(deleteCertTemplateError(error));
  }
}

function* certificatesSaga() {
  yield takeEvery(CERTIFICATE_QUOTE, certificateQuote);
  yield takeEvery(CERTIFICATE_TEMPLATE, certificateTemplate);
  yield takeEvery(CERTIFICATE_TO_PROJECT, certificateToProject);
  yield takeEvery(CREATE_CERTIFICATE, createCertificate);
  yield takeEvery(CERTIFICATE_TEMPLATE_ONE, getACertificateTemplate);
  yield takeEvery(PUT_CERT_TEMPLATE, putCertTemplate);
  yield takeEvery(SCHEMAS_TEMPLATE, getAllSchemasTemplate);
  yield takeEvery(PATCH_CERT_TEMPLATE, deleteCertTemplate);
}

export default certificatesSaga;
